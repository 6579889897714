// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgencyentryApiMethodsCls {
    async agencyEntryList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка проводок
      // filters: opcode, buyer, owner_entity, operator, payment
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('agencyEntryList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.entry.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async agencyEntryCreateCreate(wh, data) {
      // data: AgencyEntryCustomCreate
      // Создание ручной проводки
      console.log('agencyEntryCreateCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.entry.create.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyEntryRetrieve(wh, id) {
      // Получение конкретной проводки
      console.log('agencyEntryRetrieve')
      const user = ctx.$services.storage.get('finance__agencyentry', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'finance__agencyentry', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.entry.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.agencyEntryApi = new AgencyentryApiMethodsCls()
}

