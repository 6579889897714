// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgencybalanceApiMethodsCls {
    async agencyBalanceBuyerLatestList(wh, buyer_id, {limit, offset, page, sort, filters} = {}) {
      // Получение текущих балансов заказчика агентства
      // filters: entity
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('agencyBalanceBuyerLatestList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.balance.buyer.latest.list(buyer_id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async agencyBalanceBuyerList(wh, buyer_id, date, {limit, offset, page, filters} = {}) {
      // Получение текущих балансов заказчика агентства на дату
      // filters: entity
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('agencyBalanceBuyerList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.balance.buyer.list(buyer_id, date, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async agencyBalanceLatestList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение текущих балансов всех заказчиков агентства
      // filters: buyer, entity
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('agencyBalanceLatestList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.balance.latest.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async agencyBalanceList(wh, date, {limit, offset, page, filters} = {}) {
      // Получение текущих балансов всех заказчиков агентства на дату
      // filters: buyer, entity
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('agencyBalanceList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.balance.list(date, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
  }

  ctx.$services.agencyBalanceApi = new AgencybalanceApiMethodsCls()
}

