<template>
  <CFooter @click="test" class="app-footer">
    <div>
      <a href="https://cp.nsb.su/" target="_blank">НСБ©</a>
      <span class="ms-1"> 2011-2022 НСБ</span>
    </div>

    <div class="d-flex gap ms-auto">
      <span>Клиент {{clientEnvironment}} {{ clientVersion }} <span v-html="clientDate"></span></span>
      <span>Сервер {{ serverVersionStr }} <span v-html="serverDate"></span></span>
    </div>
  </CFooter>
</template>

<script>

import {onMounted, ref, inject, computed} from "vue";

const WH = 'AppFooter'

export default {

  name: WH,
  setup() {

    const { versionApi, dateService, version } = inject('services')
    const serverVersion = ref({});

    const serverVersionStr = computed(()=>{
      if ( serverVersion.value?.branch) {
        return serverVersion.value?.branch.substring(0, 8)
      }
      else {
        return ''
      }
    })
    const serverDate = computed(()=>{
      if ( serverVersion.value?.branch_date) {
        console.log(serverVersion.value?.branch_date, typeof(serverVersion.value?.branch_date))
        const d = new Date(serverVersion.value?.branch_date)
        return dateService.formatDateTime(d)
      }
      else
      {
        return ''
      }
    })
    const clientVersion = computed(()=>{
      return version.clientVersion()
    })
    const clientDate = computed(()=>{
      return dateService.formatDateTime(version.clientDate())
    })
    const clientEnvironment = computed(()=>{
      return version.clientEnvironment()
    })


    onMounted(async () => {
      serverVersion.value = await versionApi.Retrieve(WH)
    })

    return {
      serverVersion,
      serverVersionStr,
      serverDate,
      clientVersion,
      clientDate,
      clientEnvironment
    }
  },
  data() {
    return {
      state: true,
      time: '',
      interval: null,
      frontVersion: process.env.VUE_APP_VERSION,
    }
  },
  // mounted() {
  //
  //   this.interval = setInterval(this.updateClock, 100)
  // },
  methods: {
    updateClock: function () {
      let now = new Date()
      const h = this.leftPad('' + now.getHours())
      const m = this.leftPad('' + now.getMinutes())
      const s = this.leftPad('' + now.getSeconds())
      this.time = `${h} ${m} ${s}`
    },
    leftPad: function (str) {
      const leftPad = '00'
      return leftPad.substring(0, leftPad.length - str.length) + str
    },
    test() {
      console.log('object')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-footer {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: #23282c;
  background: #ffff;
  border-top: 1px solid #c8ced3;
  min-height: 50px;
}
a {
  color: #20a8d8;
  text-decoration: none;
  background-color: transparent;
}
</style>
