import { useAPI } from '@/services'
import endpoints from '@/config/api-endpoints'

async function getAplications(filters) {
  const result = await useAPI.request({ config: endpoints.core.getAplications(filters) })
  return result.data
}

async function getQuestions() {
  /* Модель: [
    {
      title: 'Text', // Заголовок
      type: 'greetings', // Варианты greetings, needs, offers. Если по-русски Приветствие Потребности Предложение
      description: 'Text с переменными', // Описание
      question: 'Text с переменными', // Вопрос
      component: 'Input', // На основе этого поля выбирается компонент. Реализованные варианты (Input, Boolean, BtnGroup, Select, MultiSelect, Date)
      requireAnswers: ['field1', 'field2'] // Вопрос не будет отображаться пока эти поля не заполнены
      answer: {
        field: 'field3', // Название поля в объекте aplication
        text: 'Имя' // Поле для визуального отображения. Пример: {{ answer.text }}: aplication[answer.field] (Имя: Артём)
      }
    }
  ]
  */
  const response = [
    {
      title: 'Узнать имя?',
      type: 'greetings',
      description: 'Описание',
      question:
        'Добрый день, меня зовут {profile_name}, вы оставили заявку на размещение в {var_hotel}, всё верно?',
      component: 'Input',
      answer: {
        field: 'name',
        text: 'Имя',
      },
    },
    {
      title: 'Вопрос номер 2? (require name)',
      type: 'greetings',
      description: 'Описание тип Boolean',
      question: '{var_name} Boolean вопрос?',
      component: 'Boolean',
      requireAnswers: ['name'],
      answer: {
        field: 'booleanQuestion',
        text: 'Ответ на boolean вопрос',
      },
    },
    {
      title: 'Вопрос номер 3? (require name)',
      type: 'greetings',
      description: 'Описание тип BtnGroup',
      question: '{var_name} BtnGroup вопрос?',
      component: 'BtnGroup',
      buttons: [
        { text: 'Text btn 1' },
        { text: 'Text btn 2' },
        { text: 'Text btn 3' },
        { text: 'Text btn 4' },
      ],
      requireAnswers: ['name'],
      answer: {
        field: 'test2',
        text: 'Ответ на BtnGroup вопрос',
      },
    },
    {
      title: 'Потребность 1',
      type: 'needs',
      description: 'Описание тип select',
      component: 'Select',
      selectDataPath: '', // Тут путь для запроса к бд
      answer: {
        field: 'select',
        text: 'Ответ на select вопрос',
      },
    },
    {
      title: 'Предложения 1',
      type: 'offers',
      description: 'Описание тип select',
      component: 'Select',
      selectDataPath: '', // Тут путь для запроса к бд
      answer: {
        field: 'select',
        text: 'Ответ на select вопрос',
      },
      final: true,
    },
  ]

  /*   const data = await useHttp(`api/scripts/script/play/1/`, 'GET')
  console.log(data) */
  return response
}

async function getObjections() {
  const response = [
    {
      title: 'Мне нужно посоветоваться с супругой',
      text: `Логика: Сначала выясняем – понравился ли самому клиенту вариант, который мы подобрали: «{profile_name}, поняла вас, скажите, пожалуйста, а вам самому (самой) нравится то, что мы подобрали с вами?»<br/><br/><br/>
      Если ложное возражение (то есть клиент отнекивается, говорит «нет, нет») - «{profile_name}, скажите, пожалуйста, а вам данный санаторий сейчас актуален? Или же вы подобрали уже другой вариант?» - выясняем правду.<br/><br/><br/>
      Если сказал «другой уже подобрал», то отрабатываем данное истинное возражение из скрипту.<br/><br/><br/>
      Если да — то спрашиваем: «Имя клиента, если есть какие-то вопросы у мужа (жены) — давайте мы свяжемся с ним и подробнее обсудим все нюансы, хорошо?» Если дает согласие — связываемся.<br/><br/><br/>
      Если не хочет давать номер: «А что является важным для этого человека при выборе санатория? Расскажите, пожалуйста, я подберу варианты исходя из предпочтений, расскажу плюсы и минусы»<br/><br/><br/>
      И далее, если человек ещё думает и не принимает решение — обязательно назначаем следующий звонок на завтра: «В какой час вам удобнее завтра созвониться? В утреннее или вечернее время?» `,
    },
    {
      title: 'Нужно подумать по стоимости',
      text: 'Test',
    },
    {
      title: 'Ещё не определились с санаторием',
      text: 'Test',
    },
  ]
  return response
}

async function startScript() {
  const result = await useAPI.request({ config: endpoints.start.startScript })
  return result.data
}

async function createApplications(data) {
  const result = await useAPI.request({ config: endpoints.core.createApplications, body: data })
  return result.data
}

async function autocomplete(data) {
  const result = await useAPI.request({ config: endpoints.core.autocomplete, body: data })
  return result.data
}

async function loadGeneric(content_types, id) {
  const result = await useAPI.request({ config: endpoints.core.loadGeneric(content_types, id) })
  return result.data
}

async function deleteApplications(id) {
  const result = await useAPI.request({ config: endpoints.core.deleteApplications(id) })
  return result.data
}

async function editApplications(data) {
  const result = await useAPI.request({ config: endpoints.core.editApplications(id), body: data })
  return result.data
}

async function fetchDataSelect(url, methods) {
  const data = await useHttp(url, methods)
  return data.data
}

export {
  getQuestions,
  getObjections,
  startScript,
  getAplications,
  fetchDataSelect,
  createApplications,
  deleteApplications,
  editApplications,
  autocomplete,
  loadGeneric
}
