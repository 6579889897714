<template>
  <div>
    <CModal ref="modal" :visible="isVisible" backdrop="static" alignment="center" fullscreen="md" @close="onClosed" @show="onOpened">
      <CModalHeader dismiss>
        <CModalTitle> Смена роли </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <template v-for="company in companies" :key="company.id">
          <h6>{{ company.name }}</h6>
          <ul>
            <li v-for="identity in companyIdentities(company.id)" :key="identity.id" @click="doChangeIdentity(identity.id)">
              <template v-if="identity.id===myIdentity.id">
                <b>{{ roleTitle(identity.role) }} <small>(#{{ identity.id }})</small> - текущая роль</b>
              </template>
              <template v-else>
                <a href="#">
                  {{ roleTitle(identity.role) }} <small>(#{{ identity.id }})</small>
                </a>
              </template>
            </li>
          </ul>
        </template>

      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="onClosed">Закрыть</CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>


<script>
import {onMounted,ref, inject} from "vue";
import C from "@/config/back_const";

const WH = 'ChangeRoleForm'

export default {
  name: 'ChangeRoleForm',
  emits: ['closed', ],

  setup(props, {emit}) {

    const myIdentity = ref({})
    const myUser = ref({})
    const isVisible = ref(false)
    const identities = ref({})
    const companies = ref([])
    const roleTitle = (role) => C.ROLES_TITLES[role]
    const newRole = ref([])

    const { storage, auth, accountApi, userApi, identityApi } = inject('services')
    const bus = inject('bus')

    onMounted(async () =>{
      myIdentity.value = await auth.getIdentity()
      storage.clr(WH)
      isVisible.value = true;
      console.log('onMounted 0')
      identities.value =  await accountApi.myrolesList(WH,{}, )
      console.log('onMounted 1', identities)
      companies.value = storage.getList('account__company', WH)
      console.log('onMounted 2', companies)
    })

    const onClosed = () => {
      console.log('onClosed')
      isVisible.value=false
      emit('closed')
      storage.clr(WH)
    }

    const onOpened = () => {
      console.log('onOpened')
    }

    const companyIdentities = (company_id) => {
      console.log('companyIdentities', company_id, identities)
      return identities.value.filter((identity) => identity.company === company_id)
    }

    const doChangeIdentity = async (identity) => {
      await auth.changeIdentity(identity)
      console.log('changed')
      isVisible.value=false
      storage.clr('TOP')  // очищаю общее хранилище приложения

      const _myIdentity = await identityApi.identityRetrieve('TOP', identity)
      myUser.value = await userApi.userRetrieve('TOP', _myIdentity.user)

      bus.emit('authentication-changed')
      emit('closed')
      storage.clr(WH) // очищаю локальное хранилище страницы
    }

    return {
      isVisible,
      onClosed,
      onOpened,
      newRole,
      identities,
      companies,
      roleTitle,
      companyIdentities,
      doChangeIdentity,
      storage,
      myIdentity
    }
  },

}
</script>
