// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class DevApiMethodsCls {
    async devErrorRetrieve(wh, code) {
      // Получение ответа с заданной ошибкой
      console.log('devErrorRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.account.dev.error.retrieve(code)})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.devApi = new DevApiMethodsCls()
}

