export const hotelsAdapter = (hotels) => {
  return hotels.map(item => {
    return {
      id: item.id || null,
      title: item.title || '-',
      area: item.title_with_city || '-',
      entities: item.company || '-',
      mode: item.mode || '-',
      responsible: '-',
      status: item.is_active || null
    }
  })
}

export const hotelAdapter = (hotel) => {
  return {
    id: hotel.id || null,
    title: hotel.title || '-',
    area: hotel.title_with_city || '-',
    entities: hotel.company || '-',
    mode: hotel.mode || '-',
    responsible: '-',
    status: hotel.is_active || null
  }
}