import { _templateMethods } from '@/api'

export default ctx => {
  class _TemplateService {
    constructor() {
      this._templateMethods = _templateMethods(ctx)
    }

    test() {
      this._templateMethods.test()
    }

    async getAllTemplateSettings() {
      return await this._templateMethods.test()
    }
  }
  ctx.$services._template = new _TemplateService()
}