/* Можно использовать */

const versionModule = require('../../../version.json')

export default ctx => {
  class VersionService  {
    clientVersion() {
      return versionModule.version
    }
    clientBuild(){
      return versionModule.build
    }
    clientDate(){
      return versionModule.date
    }
    clientEnvironment() {
      return process.env.NODE_ENV
    }
    isDev() {
      return process.env.NODE_ENV === 'development'
    }
    isProd() {
      return process.env.NODE_ENV === 'production'
    }
  }
  ctx.$services.version = new VersionService()
}
