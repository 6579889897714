// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class DiseaseApiMethodsCls {
    async diseaseList(wh, {limit, offset, page, filters} = {}) {
      // Список заболеваний
      // filters: title, category, description, definition, cause, symptoms, forms, stages,
      //          prophylaxis, methods
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('diseaseList')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.disease.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async diseaseRetrieve(wh, id) {
      // Получение заболевания по id
      console.log('diseaseRetrieve')
      const user = ctx.$services.storage.get('refbook__disease', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__disease', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.disease.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async diseaseCategoryList(wh, {limit, offset, page, sort, filters} = {}) {
      // Список категорий заболеваний
      // filters: created_at, updated_at, title, description
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('diseaseCategoryList')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.diseaseCategory.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async diseaseCategoryRetrieve(wh, id) {
      // Получение категории заболеваний по id
      console.log('diseaseCategoryRetrieve')
      const user = ctx.$services.storage.get('refbook__diseasecategory', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__diseasecategory', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.diseaseCategory.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.diseaseApi = new DiseaseApiMethodsCls()
}

