<template>
  <CHeader position="sticky">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="toggleSlideBar">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/#/"> Главная </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav
        class="px-md-0 me-md-3"
        @click="$store.commit('toggleAside')"
        style="position: relative"
      >
        <CIcon class="my-1 mx-2" icon="cil-bell" size="lg" />
      </CHeaderNav>
      <div
        class="header-dropdown bg-gray px-3 py-1"
        @click="visibleMenu = !visibleMenu"
        v-click-outside="() => (visibleMenu = fasle)"
      >
        <div class="header-dropdown__info">
          <span class="header-dropdown__role">
            {{ roleTitle(myIdentity.role) }}
          </span>
          <span class="header-dropdown__email"> {{ myUserName }} {{ myUser.surname }} </span>
        </div>
        <i :class="this.icons.chevronDown"></i>
        <CCollapse class="header-dropdown__content" :visible="visibleMenu">
          <CCard>
            <div
              class="header-dropdown__content-item"
              @click="$router.push({ path: '/common/profile' })"
            >
              Профиль
            </div>
            <div
              class="header-dropdown__content-item"
              @click="openChangeRoleForm = !openChangeRoleForm"
            >
              Сменить роль
            </div>
            <div @click="doLogout" class="header-dropdown__content-item">
              <i :class="icons.exit"></i>
              Выход
            </div>
          </CCard>
        </CCollapse>
      </div>
    </CContainer>
    <CHeaderDivider class="d-none d-md-block" />
    <CContainer fluid class="d-none d-md-block">
      <AppBreadcrumb />
    </CContainer>

    <ChangeRoleForm v-if="openChangeRoleForm" @closed="() => {openChangeRoleForm=false}"></ChangeRoleForm>
  </CHeader>

</template>

<script>
import { inject, ref, onMounted, watch } from 'vue'
import AppBreadcrumb from './AppBreadcrumb'
import { logo } from '@/assets/brand/logo'
import C from '@/config/back_const'
import ChangeRoleForm from "@/popups/common/ChangeRoleForm";
import {useRouter} from "vue-router";

const WH = 'TOP'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    ChangeRoleForm,
  },
  methods: {
    forceUpdate(){
        this.$forceUpdate()
    },
    toggleSlideBar() {
      let showAsideBar = JSON.parse(localStorage.getItem('toggleSidebar'))
      this.$store.commit('toggleSidebar', !showAsideBar)
    },
  },
  setup() {


    const router = useRouter()
    const { auth, userApi } = inject('services')
    const bus = inject('bus')

    const roleTitle = (role) => C.ROLES_TITLES[role]

    const openChangeRoleForm = ref(false)
    const myIdentity = ref({})
    const myUser = ref({})
    const reload = ref(0)
    const myUserName = ref('')

    const doLogout = () => {
      auth.resetAuthentication()
      router.push({ name: 'Login' })
    }

    watch(myUser, () => {
       reload.value += 1
       myUserName.value = myUser.value.name
      console.log(`------------------ ${myUserName.value} -------------------------`)
    })

    onMounted(async () => {
      console.log('AppHeader.')
      if (!await auth.goAuthenticated(false, false, 'point 1')) {
        // console.log('==>Login')
        // await router.push({ name: 'Login' })
      }
      else {
        myIdentity.value = await auth.getIdentity()
        console.log('AppHeader.onMounted', myIdentity)
        myUser.value = await userApi.userRetrieve(WH, myIdentity.value.user)
        console.log('onMounted myIdentity.value', myIdentity.value)
        myUserName.value = myUser.value.name
      }
    })

    bus.on('authentication-changed', async () => {
      myIdentity.value = await auth.getIdentity()
      console.log('AppHeader authentication-changed myIdentity', myIdentity.value.__storage_counter, myIdentity.value.user )
      myUser.value = await userApi.userRetrieve(WH, myIdentity.value.user)
      console.log('AppHeader authentication-changed myUser', myUser.value.__storage_counter, myUser.value.name)
    })

    return {
      logo,
      roleTitle,
      openChangeRoleForm,
      doLogout,
      auth,
      myIdentity,
      myUser,
      reload,
      myUserName
    }

  },

  data() {
    return {
      visibleMenu: false,
      myRoles: [],
    }
  },
  async created() {
    // initital toolbar state
    if (localStorage.getItem('toggleSidebar')) {
      let sideBar = JSON.parse(localStorage.getItem('toggleSidebar'));
      this.$store.commit('toggleSidebar', sideBar)
    } else {
      this.$store.commit('toggleSidebar', true);
    }
  },

}
</script>

<style lang="scss" scoped>
.role {
  &__container {
    max-height: 40vh;
    overflow: auto;
    padding: 10px;
    &:first-child {
      margin: 0 !important;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: 8px;
    padding: 10px 20px;
    border-radius: 8px;
    i {
      font-size: 25px;
    }
    &:hover {
      background-color: #e8f0ff;
    }
  }
}
</style>
