// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AttributeApiMethodsCls {
    async attributeList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Attribute
      // filters: created_at, updated_at, name
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('attributeList')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.attribute.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async attributeRetrieve(wh, id) {
      // Получение атрибута отеля
      console.log('attributeRetrieve')
      const user = ctx.$services.storage.get('refbook__attribute', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__attribute', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.attribute.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async attributeUpdate(wh, id, data) {
      // data: HotelAttributeValue
      // Обновление атрибута отеля
      console.log('attributeUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.attribute.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async attributeDelete(wh, id) {
      // Удаление атрибута отеля (не использовать, будет удалено)
      console.log('attributeDelete')
      await ctx.$services.fetch.request({config: endpoints.refbook.attribute.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
  }

  ctx.$services.attributeApi = new AttributeApiMethodsCls()
}

