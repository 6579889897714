import endpoints from '@/config/api-endpoints'

// не использовать
// использовать только автосгенеренные ручки

export const hotelMethods = ctx => {
  class HotelMethods {
    async getHotels() {
      return await ctx.$services.useAPI.request({ config: endpoints.refbook.getHotels() })
    }

    async setActivityHotel(id, value) {
      const config = endpoints.refbook.setActivityHotel(id)
      const data = { is_active: value }
      await ctx.$services.useAPI.request({ config, data })
      return value
    }

    async setHotelAsWorker(id) {
      localStorage.setItem('idHotelAsWorked', id)
    }

    async getWorkingHotel() {
      return await localStorage.getItem('idHotelAsWorked')
    }

    async getHotelById(id) {
      return await ctx.$services.useAPI.request({ config: endpoints.refbook.getHotel(id) })
    }
  }

  return new HotelMethods()
}
