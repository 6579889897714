export default {
  icons: {
    eye: 'fa fa-eye',
    comment: 'fa fa-comment',
    edit: 'fa fa-edit',
    trash: 'fa fa-trash',
    exit: 'fa fa-sign-out',
    save: 'fa fa-save',
    plus: 'fa fa-plus',
    minus: 'fa fa-minus',
    chevronDown: 'fa fa-chevron-down',
    chevronUp: 'fa fa-chevron-up',
    chat: 'fa fa-comments',
    cross: 'fa fa-times',
    filter: 'fa fa-filter',
    reply: 'fa fa-reply',
    briefcase: 'fa fa-briefcase',
    lamp: 'fa fa-lightbulb',
  },
  chessSettings: {
    maxNumberOfDays: 45
  },
  contextMenu: {
    room: [
      {
        name: 'Функция комнаты 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция комнаты 3' },
      { name: 'Функция комнаты 3' },
    ],
    category: [
      {
        name: 'Функция категории 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция категории 3' },
    ],
    total: [
      {
        name: 'Функция итоговое 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция итоговое 3' },
    ],
    status: [
      {
        name: 'Функция статуса 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция статуса 3' },
    ],
    date: [
      {
        name: 'Функция даты 1',
        callback: () => {
          console.log(
            'Произошло какое-либо действие по клику в контекстном меню',
          )
        },
      },
      { name: '---' },
      { name: 'Функция даты 3' },
    ],
  },
  cutTextLength: {
    comments: 100
  }
}
