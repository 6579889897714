// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CommissionApiMethodsCls {
    async commissionList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получить список активных планов комиссий
      // filters: name, is_active, created_at, updated_at
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('commissionList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async commissionCreate(wh, data) {
      // data: AgencyCommissionPlan
      // Создать план комиссий
      console.log('commissionCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async commissionAllList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получить список всех планов комиссий
      // filters: name, is_active, created_at, updated_at
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('commissionAllList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.all.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async commissionRetrieve(wh, id) {
      // Получить конкретный план комиссий
      console.log('commissionRetrieve')
      const user = ctx.$services.storage.get('legal__agencycommissionplan', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'legal__agencycommissionplan', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async commissionUpdate(wh, id, data) {
      // data: AgencyCommissionPlan
      // Изменить план комиссий
      console.log('commissionUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async commissionDelete(wh, id) {
      console.log('commissionDelete')
      await ctx.$services.fetch.request({config: endpoints.legal.commission.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async commissionPartial(wh, id, data) {
      // data: AgencyCommissionPlan
      console.log('commissionPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async commissionActivatePartial(wh, id, data) {
      // data: AgencyCommissionPlanActivate
      // Изменение поля is_active для AgencyCommissionPlan
      console.log('commissionActivatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.commission.activate.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.commissionApi = new CommissionApiMethodsCls()
}

