// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ScriptApiMethodsCls {
    async exitList(wh, {limit, offset, page, sort, filters} = {}) {
      // filters: created_at, updated_at, script, base_question, next_question, label, sort_order
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('exitList')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.exit.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async exitCreate(wh, data) {
      // data: Exit
      console.log('exitCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.exit.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async exitRetrieve(wh, id) {
      console.log('exitRetrieve')
      const user = ctx.$services.storage.get('script__exit', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'script__exit', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.exit.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async exitUpdate(wh, id, data) {
      // data: Exit
      console.log('exitUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.exit.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async exitDelete(wh, id) {
      console.log('exitDelete')
      await ctx.$services.fetch.request({config: endpoints.scripts.exit.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async questionList(wh, {limit, offset, page, sort, filters} = {}) {
      // filters: created_at, updated_at, script, stage, name, type, variable, condition, required,
      //          required_force, template, manager_information, sort_order, base_question
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('questionList')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.question.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async questionCreate(wh, data) {
      // data: Question
      console.log('questionCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.question.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async questionRetrieve(wh, id) {
      console.log('questionRetrieve')
      const user = ctx.$services.storage.get('script__question', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'script__question', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.question.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async questionUpdate(wh, id, data) {
      // data: Question
      console.log('questionUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.question.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async questionDelete(wh, id) {
      console.log('questionDelete')
      await ctx.$services.fetch.request({config: endpoints.scripts.question.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async scriptList(wh, {limit, offset, page, sort, filters} = {}) {
      // filters: created_at, updated_at, name, description
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('scriptList')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async scriptCreate(wh, data) {
      // data: ScriptList
      console.log('scriptCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async scriptRetrieve(wh, id) {
      console.log('scriptRetrieve')
      const user = ctx.$services.storage.get('script__salesscript', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'script__salesscript', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async scriptUpdate(wh, id, data) {
      // data: ScriptList
      console.log('scriptUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async scriptDelete(wh, id) {
      console.log('scriptDelete')
      await ctx.$services.fetch.request({config: endpoints.scripts.script.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async scriptDeepRetrieve(wh, id) {
      console.log('scriptDeepRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.script.deep.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async stageList(wh, {limit, offset, page, sort, filters} = {}) {
      // filters: created_at, updated_at, script, start, name, description, sort_order
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('stageList')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.stage.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async stageCreate(wh, data) {
      // data: Stage
      console.log('stageCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.stage.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async stageRetrieve(wh, id) {
      console.log('stageRetrieve')
      const user = ctx.$services.storage.get('script__stage', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'script__stage', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.stage.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async stageUpdate(wh, id, data) {
      // data: Stage
      console.log('stageUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.stage.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async stageDelete(wh, id) {
      console.log('stageDelete')
      await ctx.$services.fetch.request({config: endpoints.scripts.stage.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async variableList(wh, {limit, offset, page, sort, filters} = {}) {
      // filters: created_at, updated_at, script, title, default, lead_field, type, content_type,
      //          sort_order
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('variableList')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.variable.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async variableCreate(wh, data) {
      // data: Variable
      console.log('variableCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.variable.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async variableRetrieve(wh, id) {
      console.log('variableRetrieve')
      const user = ctx.$services.storage.get('script__variable', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'script__variable', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.variable.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async variableUpdate(wh, id, data) {
      // data: Variable
      console.log('variableUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.scripts.variable.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async variableDelete(wh, id) {
      console.log('variableDelete')
      await ctx.$services.fetch.request({config: endpoints.scripts.variable.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
  }

  ctx.$services.scriptApi = new ScriptApiMethodsCls()
}

