// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CategoryApiMethodsCls {
    async categoryCreate(wh, data) {
      // data: Category
      // Создание категории
      console.log('categoryCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.category.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async categoryRetrieve(wh, id) {
      // Получение категории по id
      console.log('categoryRetrieve')
      const user = ctx.$services.storage.get('refbook__category', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__category', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.category.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async categoryUpdate(wh, id, data) {
      // data: CategoryDeep
      // Обновление модели категории
      console.log('categoryUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.category.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async categoryDelete(wh, id) {
      // Удаление модели категории (не использовать, будет удалено)
      console.log('categoryDelete')
      await ctx.$services.fetch.request({config: endpoints.refbook.category.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async categoryDeepRetrieve(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получить модель отеля с вложенными сущностями
      // filters: sort_order, created_at, updated_at, type_category, hotel, services, handle, name,
      //          short_name, description, main_places, extra_places, spaces_count, total_rooms,
      //          area, bathroom_count, living_room, base_price, place_in_base_price,
      //          children_places, children_extra_places, is_active, can_book_a_bed, overbook,
      //          force_on_request, allow_on_request
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('categoryDeepRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.category.deep.retrieve(id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async categoryTypeList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка CategoryType
      // filters: name, description
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('categoryTypeList')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.categoryType.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async imageListCategoriesList(wh, hotel_id, {limit, offset, page, sort, filters} = {}) {
      // Список категорий в отеле
      // filters: sort_order, created_at, updated_at, type_category, hotel, services, handle, name,
      //          short_name, description, main_places, extra_places, spaces_count, total_rooms,
      //          area, bathroom_count, living_room, base_price, place_in_base_price,
      //          children_places, children_extra_places, is_active, can_book_a_bed, overbook,
      //          force_on_request, allow_on_request
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('imageListCategoriesList')
      const response = await ctx.$services.fetch.request({config: endpoints.media.image.listCategories.list(hotel_id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
  }

  ctx.$services.categoryApi = new CategoryApiMethodsCls()
}

