/* eslint-disable no-undef */
import mitt from 'mitt';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import vueDebounce from 'vue-debounce'
import VCalendar from 'v-calendar'
import vClickOutside from 'click-outside-vue3'
import { ServerTable, ClientTable } from 'v-tables-3'
import { services } from '@/services'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import constants from '@/config/constants'

const emitter = mitt();
const app = createApp(App)

app.component('Datepicker', Datepicker);

// SET ICONS CONSTANTS
app.config.globalProperties.icons = constants.icons

// SET GLOBAL EVENT BUS //
app.config.globalProperties.bus = emitter;
app.provide('bus', emitter)

window.axios = require('axios');

app.use(services)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(vueDebounce)
app.use(VCalendar, {})
app.use(vClickOutside)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.use(ServerTable, {}, 'bootstrap4')
app.use(ClientTable, {}, 'bootstrap4')
app.mount('#app')
