import { useAPI } from '@/services'
import endpoints from '@/config/api-endpoints'

async function getCompany(filters = null) {
  const props = filters
    ? {
        name: filters.q || '',
        is_active: filters.is_active
      }
    : null
  const result = await useAPI.request({ config: endpoints.account.company(props) })
  return result.data
}

async function editCompany(idCompany, data) {
  const result = await useAPI.request({ config: endpoints.account.editCompany(idCompany), body: data })
  return result.data
}

async function addCompany(data) {
  const result = await useAPI.request({ config: endpoints.account.addCompany, body: data })
  return result.data
}

async function getEntity(filters) {
  const result = await useAPI.request({ config: endpoints.entity.entity(filters) })
  return result.data
}

async function editEntity(id, data) {
  const result = await useAPI.request({ config: endpoints.entity.editEntity(id), body: data })
  return result.data
}

async function addEntity(data) {
  const result = await useAPI.request({ config: endpoints.entity.addEntity, body: data})
  return result.data
}

async function activeCompany(idCompany, isActive) {
  const config = endpoints.account.activeCompany(idCompany)
  const body = { is_active: isActive }
  const result = await useAPI.request({ config, body })
  return result.data
}

async function activeEntity(id, isActive) {
  const config = endpoints.entity.activeEntity(id)
  const body = { is_active: isActive }
  const result = await useAPI.request({ config, body })
  return result.data
}

async function getIdentity(id) {
  const result = await useAPI.request({ 
    config: endpoints.account.identity(id),
  })
  return result
}

async function getIdentities(idCompany, filters) {
  const result = await useAPI.request({ 
    config: endpoints.account.identities(idCompany, filters),
  })
  return result
}

async function deleteEntity(id) {
  const result = await useAPI.request({ config: endpoints.entity.deleteEntity(id)})
  return result.data
}

export {
  getCompany,
  getEntity,
  getIdentity,
  getIdentities,
  activeCompany,
  editCompany,
  editEntity,
  addCompany,
  addEntity,
  deleteEntity,
  activeEntity,
}
