// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class InviteApiMethodsCls {
    async inviteCreate(wh) {
      // Создать инвайт
      console.log('inviteCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.invite.create()})
      return ctx.$services.storage.put_response(response, wh)
    }
    async invitesList(wh) {
      // Список инвайтов
      console.log('invitesList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.invites.list()})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.inviteApi = new InviteApiMethodsCls()
}

