// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ContractApiMethodsCls {
    async contractList(wh, {limit, offset, page, filters} = {}) {
      // Получить список договоров
      // filters: base_contract, company, entity, number, is_active, conclusion_date, start_date,
      //          finish_date, approved_at, original_sent_at, signed_at, terminated_at, template,
      //          doc_isnull, object_model, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('contractList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async contractAgencyList(wh, {limit, offset, page, filters} = {}) {
      // Список договоров системы с агентством
      // filters: base_contract, company, entity, number, is_active, conclusion_date, start_date,
      //          finish_date, approved_at, original_sent_at, signed_at, terminated_at, template,
      //          doc_isnull, object_model, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('contractAgencyList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async contractAgencyCreate(wh, data) {
      // data: AgencyContract
      // Создать договор системы с агентством
      console.log('contractAgencyCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractAgencyRetrieve(wh, id) {
      // Получить договор системы с агентством
      console.log('contractAgencyRetrieve')
      const user = ctx.$services.storage.get('legal__contract', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'legal__contract', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractAgencyUpdate(wh, id, data) {
      // data: AgencyContractUpdate
      // Изменить договор системы с агентством
      console.log('contractAgencyUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractAgencyDelete(wh, id) {
      console.log('contractAgencyDelete')
      await ctx.$services.fetch.request({config: endpoints.legal.contract.agency.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async contractHotelierList(wh, {limit, offset, page, filters} = {}) {
      // Список договоров системы с отелем
      // filters: base_contract, company, entity, number, is_active, conclusion_date, start_date,
      //          finish_date, approved_at, original_sent_at, signed_at, terminated_at, template,
      //          doc_isnull, object_model, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('contractHotelierList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async contractHotelierCreate(wh, data) {
      // data: HotelierContract
      // Создать договор системы с отелем
      console.log('contractHotelierCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractHotelierRetrieve(wh, id) {
      // Получить договор системы с отелем
      console.log('contractHotelierRetrieve')
      const user = ctx.$services.storage.get('legal__contract', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'legal__contract', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractHotelierUpdate(wh, id, data) {
      // data: HotelierContractUpdate
      // Изменить договор системы с отелем
      console.log('contractHotelierUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractHotelierDelete(wh, id) {
      console.log('contractHotelierDelete')
      await ctx.$services.fetch.request({config: endpoints.legal.contract.hotelier.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async contractRetrieve(wh, id) {
      // Получить конкретный договор без данных соответствующего ему типа
      console.log('contractRetrieve')
      const user = ctx.$services.storage.get('legal__contract', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'legal__contract', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractApprovePartial(wh, id) {
      // Одобрить договор
      console.log('contractApprovePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.approve.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractOriginalSendPartial(wh, id) {
      // Выслать оригинал договора
      console.log('contractOriginalSendPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.originalSend.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractResumePartial(wh, id) {
      // Возобновить договор
      console.log('contractResumePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.resume.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractSignPartial(wh, id) {
      // Подписать договор
      console.log('contractSignPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.sign.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async contractTerminatePartial(wh, id) {
      // Расторгнуть договор
      console.log('contractTerminatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.contract.terminate.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.contractApi = new ContractApiMethodsCls()
}

