// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class HotelserviceApiMethodsCls {
    async hotelServiceList(wh, {limit, offset, page, filters} = {}) {
      // Список услуг отеля
      // filters: title, category
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('hotelServiceList')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotelService.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async hotelServiceRetrieve(wh, id) {
      // Получение услуги отеля по id
      console.log('hotelServiceRetrieve')
      const user = ctx.$services.storage.get('refbook__hotelservice', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__hotelservice', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotelService.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async hotelServiceCategoryList(wh, {limit, offset, page, sort, filters} = {}) {
      // Список категорий услуг отеля
      // filters: created_at, updated_at, title, description, sort_order
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelServiceCategoryList')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotelServiceCategory.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async hotelServiceCategoryRetrieve(wh, id) {
      // Получение категории услуг отеля по id
      console.log('hotelServiceCategoryRetrieve')
      const user = ctx.$services.storage.get('refbook__hotelservicecategory', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__hotelservicecategory', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotelServiceCategory.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async roomServiceList(wh, {limit, offset, page, filters} = {}) {
      // Список услуг номера
      // filters: title, category
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('roomServiceList')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.roomService.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async roomServiceRetrieve(wh, id) {
      // Получение услуги номера по id
      console.log('roomServiceRetrieve')
      const user = ctx.$services.storage.get('refbook__roomservice', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__roomservice', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.roomService.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.hotelServiceApi = new HotelserviceApiMethodsCls()
}

