// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class MealApiMethodsCls {
    async hotelMealsRetrieve(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка программ питания для отеля
      // filters: created_at, updated_at, name, description, type, is_active
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelMealsRetrieve')
      const user = ctx.$services.storage.get('refbook__meal', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__meal', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.meals.retrieve(id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async mealCreate(wh, data) {
      // data: Meal
      // Создание Программы питания
      console.log('mealCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.meal.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async mealRetrieve(wh, id) {
      // Обновление Программы питания
      console.log('mealRetrieve')
      const user = ctx.$services.storage.get('refbook__meal', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__meal', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.meal.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async mealUpdate(wh, id, data) {
      // data: Meal
      // Обновление Программы питания
      console.log('mealUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.meal.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async mealDelete(wh, id) {
      // Удаление Программы питания
      console.log('mealDelete')
      await ctx.$services.fetch.request({config: endpoints.refbook.meal.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async mealPartial(wh, id, data) {
      // data: MealIsActive
      // Изменение поля is_active Программы питания
      console.log('mealPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.meal.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.mealApi = new MealApiMethodsCls()
}

