import { getCompany } from '@/api/company'
const state = () => ({
  companies: [],
})

const getters = {
  companies: (state) => {
    return state.companies
  },
}

const mutations = {
  SET_COMPANIES(state, payload) {
    state.companies = payload
  },
}

const actions = {
  async SET_COMPANIES({ commit }, payload) {
    const response = payload ? payload : await getCompany({ is_active: true })
    commit('SET_COMPANIES', response)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
