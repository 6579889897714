// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgencypaymentApiMethodsCls {
    async agencyEntityPaymentsList(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка платежей для юр. лица
      // filters: created_at, updated_at, owner_entity, operator_identity, state, object_type,
      //          doc_number, doc_date_after, doc_date_before, import_agency_payments, article,
      //          invoice_number, invoice_date_after, invoice_date_before, statement_number,
      //          statement_date_after, statement_date_before, amount_min, amount_max, type, info,
      //          cash, buyer_name, inn, kpp, bik, bank_account, buyer, uuid1c, split_amount, pk,
      //          split
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('agencyEntityPaymentsList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.entity.payments.list(id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async agencyPaymentCreateCreate(wh, data) {
      // data: AgencyPayment
      // Создание платежа агентству
      console.log('agencyPaymentCreateCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.create.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentImportCreate(wh, data) {
      // data: ImportAgencyPaymentsCreate
      // Импорт файла платежей агентству
      console.log('agencyPaymentImportCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.import.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentImportAcceptPartial(wh, id, data) {
      // data: ImportAgencyPaymentsAccept
      // Принятие платежей агентству
      console.log('agencyPaymentImportAcceptPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.import.accept.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentSplitCreate(wh, data) {
      // data: AgencyPaymentSplit
      // Создание разнесения платежа покупателя
      console.log('agencyPaymentSplitCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.split.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentSplitRetrieve(wh, id) {
      // Получение конкретного разнесения платежа покупателя
      console.log('agencyPaymentSplitRetrieve')
      const user = ctx.$services.storage.get('finance__agencypayment', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'finance__agencypayment', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.split.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentSplitDeleteDelete(wh, id) {
      // Удаление разнесения платежа покупателя
      console.log('agencyPaymentSplitDeleteDelete')
      await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.split.delete.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async agencyPaymentSplitUpdatePartial(wh, id, data) {
      // data: AgencyPaymentSplitUpdate
      // Обновление разнесения платежа покупателя
      console.log('agencyPaymentSplitUpdatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.split.update.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentRetrieve(wh, id) {
      // Получение конкретного платежа агентству
      console.log('agencyPaymentRetrieve')
      const user = ctx.$services.storage.get('finance__agencypayment', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'finance__agencypayment', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentAcceptPartial(wh, id) {
      // Принять платеж покупателя
      console.log('agencyPaymentAcceptPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.accept.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentAnnulPartial(wh, id) {
      // Аннулировать платеж покупателя
      console.log('agencyPaymentAnnulPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.annul.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentCancelPartial(wh, id) {
      // Отменить платеж покупателя
      console.log('agencyPaymentCancelPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.cancel.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentHoldPartial(wh, id) {
      // Провести платеж покупателя
      console.log('agencyPaymentHoldPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.hold.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentRestorePartial(wh, id) {
      // Восстановить платеж покупателя
      console.log('agencyPaymentRestorePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.restore.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentSetBuyerPartial(wh, id, data) {
      // data: AgencyPaymentBuyer
      // Установить покупателя для платежа
      console.log('agencyPaymentSetBuyerPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.setBuyer.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agencyPaymentListSplitList(wh, id, {limit, offset, page} = {}) {
      // Получение списка разнесений для конкретного платежа покупателя
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('agencyPaymentListSplitList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.listSplit.list(id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
  }

  ctx.$services.agencyPaymentApi = new AgencypaymentApiMethodsCls()
}

