// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class TourApiMethodsCls {
    async tourCreate(wh, data) {
      // data: TourCreate
      // Создание тура (Tour)
      console.log('tourCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async tourAddPersonPartial(wh, id, data) {
      // data: TourAddPerson
      // Добавление персоны (Person)
      console.log('tourAddPersonPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.tour.addPerson.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.tourApi = new TourApiMethodsCls()
}

