// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class OrganizationApiMethodsCls {
    async organizationList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Organization
      // filters: full_title, ogrn, inn, kpp, okpo, address, postal_address, email
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('organizationList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async organizationCreate(wh, data) {
      // data: OrganizationPost
      // Создание Organization
      // Если роль не CUSTOMER, то должен быть указан buyer
      console.log('organizationCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async organizationRetrieve(wh, id) {
      // Получение Organization
      console.log('organizationRetrieve')
      const user = ctx.$services.storage.get('core__organization', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'core__organization', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async organizationUpdate(wh, id, data) {
      // data: OrganizationUpdate
      // Обновление Organization
      console.log('organizationUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async organizationDelete(wh, id) {
      // Удаление Organization
      console.log('organizationDelete')
      await ctx.$services.fetch.request({config: endpoints.core.organization.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async organizationResponsiblePartial(wh, id, data) {
      // data: PersonChangeResponsible
      // Изменение responsible у Organization
      console.log('organizationResponsiblePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.organization.responsible.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.organizationApi = new OrganizationApiMethodsCls()
}

