// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class FmsApiMethodsCls {
    async fmsList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Fms (фмс карточка)
      // filters: tourist, document_type, document_number, document_date, document_expire_date,
      //          purpose_of_entry, profession, arrival_date, stay_up_to, migration_card,
      //          legal_representatives_info, previous_address
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('fmsList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.fms.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async fmsCreate(wh, data) {
      // data: Fms
      // Создание Fms (фмс карточка)
      console.log('fmsCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.fms.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async fmsRetrieve(wh, id) {
      // Получение Fms (фмс карточка)
      console.log('fmsRetrieve')
      const user = ctx.$services.storage.get('core__fms', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'core__fms', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.core.fms.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async fmsUpdate(wh, id, data) {
      // data: Fms
      // Обновление Fms (фмс карточка)
      console.log('fmsUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.fms.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async fmsDelete(wh, id) {
      // Удаление Fms (фмс карточка)
      console.log('fmsDelete')
      await ctx.$services.fetch.request({config: endpoints.core.fms.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
  }

  ctx.$services.fmsApi = new FmsApiMethodsCls()
}

