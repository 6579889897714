/* Можно использовать */

import C from '@/config/back_const.js'

export default ctx => {
  class CommentService  {

    getEmptyComment(dataType, dataId) {
      return {
        object_type: dataType,
        object_id: dataId,
        type: C.COMMENT_TYPE_COMPANY,
        text: null,
      }
    }
  }

  ctx.$services.commentService = new CommentService()
}
