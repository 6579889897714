/* Можно использовать */
export default ctx => {
  class LocaleService  {
    getLocale() {
      return {
        locale: 'ru-RU',
      }
    }
  }
  ctx.$services.locale = new LocaleService()
}
