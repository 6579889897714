// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class MediaApiMethodsCls {
    async filePut(wh, data) {
      // data: FileGet
      // Получение файлов
      console.log('filePut')
      const response = await ctx.$services.fetch.request({config: endpoints.media.file.put(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async fileDelete(wh, data) {
      // data: MediaDelete
      // Удаление файла
      console.log('fileDelete')
      await ctx.$services.fetch.request({config: endpoints.media.file.delete(), body: data})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async fileUploadCreate(wh, data, file) {
      // data: {object_type, object_id, sort_order, title}
      // Загрузка файла
      console.log('fileUploadCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.media.file.upload.create(), file: file})
      return ctx.$services.storage.put_response(response, wh)
    }
    async fileListForObjectList(wh, data_type, id) {
      // Получение всех файлов объекта
      console.log('fileListForObjectList')
      const response = await ctx.$services.fetch.request({config: endpoints.media.file.listForObject.list(data_type, id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async imagePut(wh, data) {
      // data: ImageGet
      // Получение изображений
      console.log('imagePut')
      const response = await ctx.$services.fetch.request({config: endpoints.media.image.put(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async imageDelete(wh, data) {
      // data: MediaDelete
      // Удаление изображения
      console.log('imageDelete')
      await ctx.$services.fetch.request({config: endpoints.media.image.delete(), body: data})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async imageUploadCreate(wh, data, file) {
      // data: {object_type, object_id, fill_color, width, height, sort_order, title, fixed_title}
      // Загрузка изображения
      console.log('imageUploadCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.media.image.upload.create(), file: file})
      return ctx.$services.storage.put_response(response, wh)
    }
    async imageListForObjectList(wh, data_type, id) {
      // Получение всех изображений объекта
      console.log('imageListForObjectList')
      const response = await ctx.$services.fetch.request({config: endpoints.media.image.listForObject.list(data_type, id)})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.mediaApi = new MediaApiMethodsCls()
}

