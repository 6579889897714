// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ShiftApiMethodsCls {
    async hotelShiftsRetrieve(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка смен для отеля
      // filters: created_at, updated_at, name, check_in, check_out
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelShiftsRetrieve')
      const user = ctx.$services.storage.get('refbook__shift', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__shift', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.shifts.retrieve(id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async shiftCreate(wh, data) {
      // data: Shift
      // Создание Смены
      console.log('shiftCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.shift.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async shiftRetrieve(wh, id) {
      // Обновление Смены
      console.log('shiftRetrieve')
      const user = ctx.$services.storage.get('refbook__shift', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'refbook__shift', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.shift.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async shiftUpdate(wh, id, data) {
      // data: Shift
      // Обновление Смены
      console.log('shiftUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.shift.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async shiftDelete(wh, id) {
      // Удаление Смены
      console.log('shiftDelete')
      await ctx.$services.fetch.request({config: endpoints.refbook.shift.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
  }

  ctx.$services.shiftApi = new ShiftApiMethodsCls()
}

