  /* Можно использовать */

export default ctx => {
  class PersonService  {

    getEmptyPerson({owner})
    {
      return {
        name: null,
        surname: null,
        patronymic: null,
        birthday: null,
        age: null,
        gender: null,
        document_type: null,
        registration_address: null,
        passport_number: null,
        passport_date: null,
        email: null,
        social_group: [],
        country: null,
        city_of_resident: null,
        owner: owner,
      }
    }
  }

  ctx.$services.personService = new PersonService()
}
