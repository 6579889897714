// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class VersionApiMethodsCls {
    async Retrieve(wh) {
      // Получение информации о версии
      console.log('Retrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.version.retrieve()})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.versionApi = new VersionApiMethodsCls()
}

