// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class PhoneApiMethodsCls {
    async phoneList(wh, data_type, object_id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка телефонов для объекта
      // filters: number, info
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('phoneList')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phone.list(data_type, object_id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async phoneCreate(wh, data_type, object_id, data) {
      // data: PhoneCreate
      // Создание телефона
      console.log('phoneCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phone.create(data_type, object_id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async phoneRetrieve(wh, data_type, id, object_id) {
      // Получение телефона
      console.log('phoneRetrieve')
      const user = ctx.$services.storage.get('telephony__phone', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'telephony__phone', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phone.retrieve(data_type, id, object_id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async phoneDelete(wh, data_type, id, object_id) {
      // Удаление телефона
      console.log('phoneDelete')
      await ctx.$services.fetch.request({config: endpoints.telephony.phone.delete(data_type, id, object_id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async phonePartial(wh, data_type, id, object_id, data) {
      // data: PhoneUpdate
      // Обновление поля info у телефона
      console.log('phonePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.phone.partial(data_type, id, object_id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.phoneApi = new PhoneApiMethodsCls()
}

