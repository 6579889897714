// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CoreApiMethodsCls {
    async agentFeeList(wh, {limit, offset, page} = {}) {
      // Получение списка док-тов начисления вознаграждений
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('agentFeeList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentFee.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async agentFeeCreate(wh, data) {
      // data: CreateAgentSalaryAccrual
      // Создание док-та начисления вознаграждений
      console.log('agentFeeCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentFee.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agentFeeRetrieve(wh, id) {
      // Получение док-та начисления вознаграждений
      console.log('agentFeeRetrieve')
      const user = ctx.$services.storage.get('core__agentsalaryaccrual', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'core__agentsalaryaccrual', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentFee.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agentFeeUpdate(wh, id, data) {
      // data: FullAccrualDocumentUpdate
      // Обновление док-та начисления вознаграждений
      console.log('agentFeeUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentFee.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agentFeePartial(wh, id) {
      console.log('agentFeePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentFee.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agentFeeApprovePartial(wh, id) {
      // Утверждение док-та начисления вознаграждений
      console.log('agentFeeApprovePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentFee.approve.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async agentFeeRemovePartial(wh, id) {
      // Удаление док-та начисления вознаграждений
      console.log('agentFeeRemovePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.agentFee.remove.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.coreApi = new CoreApiMethodsCls()
}

