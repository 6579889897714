/* Можно использовать */

export default ctx => {
  class UserService  {

    getPrintName(user)
    {
      if (!user) {
        return 'Несуществующий пользователь'
      }
      const names = []
      if (user.surname) {
        names.push(user.surname)
      }
      if (user.name) {
        names.push(user.name)
      }
      if (user.patronymic) {
        names.push(user.patronymic)
      }
      if (names.length > 0) {
        return names.join(' ')
      }
      return `Пользователь ${names.id}`
    }
  }

  ctx.$services.userService = new UserService()
}
