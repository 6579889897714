// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class OccupationApiMethodsCls {
    async hotelOccupationRetrieve(wh, id, {limit, offset, page} = {}) {
      // Получение списка Occupation для отеля
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('hotelOccupationRetrieve')
      const user = ctx.$services.storage.get('hms__occupation', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'hms__occupation', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.occupation.retrieve(id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async occupationCreate(wh, data) {
      // data: OccupationCreate
      // Создание Occupation
      console.log('occupationCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationRetrieve(wh, id) {
      // Получение Occupation
      console.log('occupationRetrieve')
      const user = ctx.$services.storage.get('hms__occupation', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'hms__occupation', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationUpdate(wh, id, data) {
      // data: OccupationUpdate
      // Обновление модели Occupation
      console.log('occupationUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationDelete(wh, id) {
      // Удаление Occupation, только для статуса Draft
      console.log('occupationDelete')
      await ctx.$services.fetch.request({config: endpoints.hms.occupation.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async occupationPartial(wh, id, data) {
      // data: OccupationUpdate
      // Обновление полей Occupation
      console.log('occupationPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationAddResidentPost(wh, id, data) {
      // data: ResidentCreate
      // Создание Resident для occupation
      console.log('occupationAddResidentPost')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.addResident.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationCancelPartial(wh, id) {
      // Отмена. Переводит в статус CANCELED
      console.log('occupationCancelPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.cancel.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationCheckInPartial(wh, id) {
      // Переводит в статус CHECK_IN
      console.log('occupationCheckInPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.checkIn.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationCheckOutPartial(wh, id) {
      // Переводит в статус CHECK_OUT
      console.log('occupationCheckOutPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.checkOut.partial(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationMovePartial(wh, id, data) {
      // data: OccupationMove
      // Переезд в другой номер
      console.log('occupationMovePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.move.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async occupationProlongPartial(wh, id, data) {
      // data: OccupationProlong
      // Продление. Меняет плановое время, если есть свободные места
      console.log('occupationProlongPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.occupation.prolong.partial(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async residentUpdate(wh, id, data) {
      // data: Resident
      // Обновление Resident
      console.log('residentUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.resident.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async residentDelete(wh, id) {
      // Удаление Resident
      console.log('residentDelete')
      await ctx.$services.fetch.request({config: endpoints.hms.resident.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
  }

  ctx.$services.occupationApi = new OccupationApiMethodsCls()
}

