import { API } from '@/api'
import { apiList } from '@/api/modules/api'  //  Подключаются все API методы скопом

/* system */
import storageService from  './modules/storageService'
import fetchService from './modules/fetchService'
import authService from  './modules/authService'
import localeService from  './modules/localeService'
import dateService from  './modules/dateService'

/* data */
import templateService from './modules/_templateService'
import userService from  './modules/userService'
import hotelService from  './modules/hotelService'
import roomService from  './modules/roomService'
import versionService from  './modules/versionService'
import leadService from  './modules/leadService'
import commentService from  './modules/commentService'
import personService from  './modules/personService'


const servicesList = [
  leadService,
  templateService,
  storageService,
  authService,
  fetchService,
  localeService,
  userService,
  hotelService,
  roomService,
  dateService,
  versionService,
  commentService,
  personService,
]

export const services = (context) => {
  class ServiceLocator {}
  context.$services = new ServiceLocator()
  // Копирование апи в $api. Начало //
  apiList.forEach(fn => fn(context))
  context.use(API)
  // Копирование апи в $api. Конец //
  servicesList.forEach(fn => fn(context))
  context.provide('services', context.$services)
  console.log(context.$services)
}
