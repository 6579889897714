export const _templateMethods = ctx => {
  class _TemplateMethods {
    test() {
      console.log('Проверка тестовой функции');
    }
    async getAllTemplateSettings(params = {}) {
      return await ctx.useApi?.request(params)
    }
  }

  return new _TemplateMethods()
}