<template>
  <CSidebar
    color-scheme="light"
    size="lg"
    overlaid
    placement="end"
    :visible="asideVisible"
  >
    <CSidebarHeader class="bg-transparent p-0">
      <CNav variant="underline">
        <CNavItem>
          <CNavLink href="#" @click="updateActiveKey(1)">
            <CIcon icon="cil-List" alt="CoreUI Icons List" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#" @click="updateActiveKey(2)">
            <CIcon icon="cil-speech" alt="CoreUI Icons Speech" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#" @click="updateActiveKey(3)">
            <CIcon icon="cil-settings" alt="CoreUI Icons Settings" />
          </CNavLink>
        </CNavItem>
        <CNavItem class="ms-auto me-2 d-flex align-items-center">
          <CCloseButton @click="$store.commit('toggleAside')" />
        </CNavItem>
      </CNav>
    </CSidebarHeader>
    <CTabContent>
      <CTabPane :visible="activeKey == 1">
        <CListGroup flush>
          <CListGroupItem
            class="list-group-item border-start-4 border-start-secondary bg-light text-center fw-bold text-medium-emphasis text-uppercase small"
          >
            Today
          </CListGroupItem>
          <CListGroupItem href="#" class="border-start-4 border-start-warning">
            <CAvatar :src="avatar7" size="lg" class="float-end" />
            <div>Meeting with <strong>Lucas</strong></div>
            <small class="text-medium-emphasis me-3">
              <CIcon icon="cil-calendar" /> 1 - 3pm
            </small>
            <small class="text-medium-emphasis">
              <CIcon icon="cil-location-pin" /> Palo Alto, CA
            </small>
          </CListGroupItem>
          <CListGroupItem href="#" class="border-start-4 border-start-info">
            <CAvatar :src="avatar4" size="lg" class="float-end" />
            <div>Skype with <strong>Megan</strong></div>
            <small class="text-medium-emphasis me-3">
              <CIcon icon="cil-calendar" /> 4 - 5pm
            </small>
            <small class="text-medium-emphasis">
              <CIcon icon="cib-skype" /> On-line
            </small>
          </CListGroupItem>
          <CListGroupItem
            class="border-start-4 border-start-secondary bg-light text-center fw-bold text-medium-emphasis text-uppercase small"
          >
            Tomorrow
          </CListGroupItem>
          <CListGroupItem
            accent="danger"
            href="#"
            class="border-start-4 border-start-danger"
          >
            <div>New UI Project - <strong>deadline</strong></div>
            <small class="text-medium-emphasis me-3">
              <CIcon icon="cil-calendar" /> 10 - 11pm
            </small>
            <small class="text-medium-emphasis">
              <CIcon icon="cil-home" /> creativeLabs HQ
            </small>
            <div class="avatars-stack mt-2">
              <CAvatar :src="avatar2" size="sm" />
              <CAvatar :src="avatar3" size="sm" />
              <CAvatar :src="avatar4" size="sm" />
              <CAvatar :src="avatar5" size="sm" />
              <CAvatar :src="avatar6" size="sm" />
            </div>
          </CListGroupItem>
          <CListGroupItem href="#" class="border-start-4 border-start-success">
            <div><strong>#10 Startups.Garden</strong> Meetup</div>
            <small class="text-medium-emphasis me-3">
              <CIcon icon="cil-calendar" /> 1 - 3pm
            </small>
            <small class="text-medium-emphasis">
              <CIcon icon="cil-location-pin" /> Palo Alto, CA
            </small>
          </CListGroupItem>
          <CListGroupItem
            href="#"
            class="border-start-4 border-start-primary border-bottom"
          >
            <div>
              <strong>Team meeting</strong>
            </div>
            <small class="text-medium-emphasis me-3">
              <CIcon icon="cil-calendar" /> 4 - 6pm
            </small>
            <small class="text-medium-emphasis">
              <CIcon icon="cil-home" /> creativeLabs HQ
            </small>
            <div class="/avatars-stack mt-2">
              <CAvatar :src="avatar2" size="sm" />
              <CAvatar :src="avatar3" size="sm" />
              <CAvatar :src="avatar4" size="sm" />
              <CAvatar :src="avatar5" size="sm" />
              <CAvatar :src="avatar6" size="sm" />
              <CAvatar :src="avatar7" size="sm" />
              <CAvatar :src="avatar8" size="sm" />
            </div>
          </CListGroupItem>
        </CListGroup>
      </CTabPane>
      <CTabPane class="p-3" :visible="activeKey == 2">
        <div class="message">
          <div class="py-3 pb-5 me-3 float-start">
            <CAvatar :src="avatar7" status="success" size="md" />
          </div>
          <div>
            <small class="text-medium-emphasis">Lukasz Holeczek</small>
            <small class="text-medium-emphasis float-end mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate fw-semibold">
            Lorem ipsum dolor sit amet
          </div>
          <small class="text-medium-emphasis">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt...
          </small>
        </div>
        <hr />
        <div class="message">
          <div class="py-3 pb-5 me-3 float-start">
            <CAvatar :src="avatar7" status="success" size="md" />
          </div>
          <div>
            <small class="text-medium-emphasis">Lukasz Holeczek</small>
            <small class="text-medium-emphasis float-end mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate fw-semibold">
            Lorem ipsum dolor sit amet
          </div>
          <small class="text-medium-emphasis">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt...
          </small>
        </div>
        <hr />
        <div class="message">
          <div class="py-3 pb-5 me-3 float-start">
            <CAvatar :src="avatar7" status="success" size="md" />
          </div>
          <div>
            <small class="text-medium-emphasis">Lukasz Holeczek</small>
            <small class="text-medium-emphasis float-end mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate fw-semibold">
            Lorem ipsum dolor sit amet
          </div>
          <small class="text-medium-emphasis">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt...
          </small>
        </div>
        <hr />
        <div class="message">
          <div class="py-3 pb-5 me-3 float-start">
            <CAvatar :src="avatar7" status="success" size="md" />
          </div>
          <div>
            <small class="text-medium-emphasis">Lukasz Holeczek</small>
            <small class="text-medium-emphasis float-end mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate fw-semibold">
            Lorem ipsum dolor sit amet
          </div>
          <small class="text-medium-emphasis">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt...
          </small>
        </div>
        <hr />
        <div class="message">
          <div class="py-3 pb-5 me-3 float-start">
            <CAvatar :src="avatar7" status="success" size="md" />
          </div>
          <div>
            <small class="text-medium-emphasis">Lukasz Holeczek</small>
            <small class="text-medium-emphasis float-end mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate fw-semibold">
            Lorem ipsum dolor sit amet
          </div>
          <small class="text-medium-emphasis">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt...
          </small>
        </div>
      </CTabPane>
      <CTabPane class="p-3" :visible="activeKey == 3">
        Тут будут все события
        {{ toasts }}
      </CTabPane>
    </CTabContent>
  </CSidebar>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import avatar2 from '@/assets/images/avatars/2.jpg'
import avatar3 from '@/assets/images/avatars/3.jpg'
import avatar4 from '@/assets/images/avatars/4.jpg'
import avatar5 from '@/assets/images/avatars/5.jpg'
import avatar6 from '@/assets/images/avatars/6.jpg'
import avatar7 from '@/assets/images/avatars/7.jpg'
import avatar8 from '@/assets/images/avatars/8.jpg'
import { mapGetters } from 'vuex'

export default {
  name: 'AppAside',
  setup() {
    const activeKey = ref(1)

    const updateActiveKey = (key) => {
      activeKey.value = key
    }

    const store = useStore()

    return {
      asideVisible: computed(() => store.state.asideVisible),
      avatar2,
      avatar3,
      avatar4,
      avatar5,
      avatar6,
      avatar7,
      avatar8,
      activeKey,
      updateActiveKey,
    }
  },
  computed: {
    ...mapGetters({
      toasts: 'toasts',
    }),
  },
}
</script>
