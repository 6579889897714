// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class LeadApiMethodsCls {
    async leadList(wh, {limit, offset, page, filters} = {}) {
      // Получение списка Заявок
      // filters: created_at, updated_at, company_owner, status, result, source, url, initiator,
      //          agent, buyer, taken_at, postponed_till, closed_at, location, diseases, hotel,
      //          room, program, number_of_adults, number_of_children, check_in_fr, check_in_to,
      //          days_fr, days_to, fio, email, source_ip, form_id, text
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('leadList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async leadCreate(wh, data) {
      // data: LeadCreate
      // Создание Заявки
      console.log('leadCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async leadRetrieve(wh, id) {
      // Получение Заявки
      console.log('leadRetrieve')
      const user = ctx.$services.storage.get('core__lead', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'core__lead', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async leadUpdate(wh, id, data) {
      // data: LeadCreate
      // Обновление Заявки
      console.log('leadUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.lead.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.leadApi = new LeadApiMethodsCls()
}

