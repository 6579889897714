/* Не использовать - будет полностью переделано */

import moment from 'moment'
import { sectionBookingAdapter } from '@/adaptors'

export default ctx => {
  class RoomService {
    static #hmsApiMethods = ctx?.$api?.hmsApi
    static #reservationApiMethods = ctx?.$api?.reservationApi
    static #storage = ctx?.$services?.storage

    async getRoomsByHotelId(id) {
      const rooms = await RoomService.#hmsApiMethods.hotelCrossRoomsRetrieve('ROOMS', id) || []
      const categories = await RoomService.#storage.getList('refbook__category', 'ROOMS') || {}
      const reservations = await RoomService.#reservationApiMethods.hotelReservationRetrieve('ROOMS', id)
      const startDate = moment(new Date()).format('YYYY-MM-DD');
      return sectionBookingAdapter({ rooms, categories, reservations, date: startDate})
    }

    async getRoomsByHotelIdWithDates(id, dateFrom, dateTo) {
      const data = {
        "date_fr": dateFrom,
        "date_to": dateTo,
        "rooms": []
      }
      const rooms = await RoomService.#hmsApiMethods.hotelCrossRoomsUpdate('ROOMS', id, data) || {}
      const categories = await RoomService.#storage.getList('refbook__category', 'ROOMS') || {}
      const reservations = Object.values(await RoomService.#storage.getList('hms__reservation', 'ROOMS')) || await RoomService.#reservationApiMethods.hotelReservationRetrieve('ROOMS', id)
      const startDate = moment(dateFrom).format('YYYY-MM-DD');
      return sectionBookingAdapter({ rooms, categories, reservations, date: startDate})
    }
  }
  ctx.$services.room = new RoomService()
}
