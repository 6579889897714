// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class HmsApiMethodsCls {
    async flagDelete(wh, id) {
      // Удаление RoomFlag
      console.log('flagDelete')
      await ctx.$services.fetch.request({config: endpoints.hms.flag.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async guestUpdate(wh, id, data) {
      // data: Guest
      // Обновление Guest
      console.log('guestUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.guest.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async guestDelete(wh, id) {
      // Удаление Guest
      console.log('guestDelete')
      await ctx.$services.fetch.request({config: endpoints.hms.guest.delete(id)})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async hotelCrossAvailabilityRetrieve(wh, id) {
      // Данные для шахматки по доступности для текущего периода (не реализовано)
      // Пока не реализовано
      console.log('hotelCrossAvailabilityRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.availability.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async hotelCrossAvailabilityUpdate(wh, id, data) {
      // data: CrossRequest
      // Данные для шахматки по доступности для заданного периода (не реализовано)
      // Пока не реализовано
      console.log('hotelCrossAvailabilityUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.availability.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async hotelCrossPricesRetrieve(wh, id) {
      // Данные для шахматки по ценам для текущего периода (не реализовано)
      // Пока не реализовано
      console.log('hotelCrossPricesRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.prices.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async hotelCrossPricesUpdate(wh, id, data) {
      // data: CrossRequest
      // Данные для шахматки по ценам для заданного периода (не реализовано)
      // Пока не реализовано
      console.log('hotelCrossPricesUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.prices.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async hotelCrossRoomsRetrieve(wh, id) {
      // Данные для шахматки по номерам для текущего периода
      console.log('hotelCrossRoomsRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.rooms.retrieve(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async hotelCrossRoomsUpdate(wh, id, data) {
      // data: CrossRequest
      // Данные для шахматки по номерам для заданного периода
      // Фильтр по номерам пока не реализован
      console.log('hotelCrossRoomsUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.cross.rooms.update(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async hotelPeriodRetrieve(wh, id, {limit, offset, page, filters} = {}) {
      // Получение списка периодов для отеля
      // filters: is_active
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('hotelPeriodRetrieve')
      const user = ctx.$services.storage.get('hms__period', id, wh)
      if (user) return user
      ctx.$services.storage.put({data_type:'hms__period', id:id}, wh)
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.period.retrieve(id, params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
    async hotelPeriodPost(wh, id, data) {
      // data: PeriodPost
      // Создание периода для отеля
      console.log('hotelPeriodPost')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.period.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async reservationAddGuestPost(wh, id, data) {
      // data: GuestCreate
      // Создание Guest для reservation
      console.log('reservationAddGuestPost')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.addGuest.post(id), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
  }

  ctx.$services.hmsApi = new HmsApiMethodsCls()
}

