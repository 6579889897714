<template>
   <CToaster placement="bottom-start">
    <CToast
      v-for="(toast, i) in toasts"
      :key="'toast' + i"
      :color="toast.error ? 'danger' : 'success'"
      class="text-white align-items-center opacity-75"
    >
      <CToastBody v-html="toast.text" />
    </CToast>
  </CToaster>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      toasts: 'toasts',
    }),
  },
}
</script>
