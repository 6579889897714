// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AccountApiMethodsCls {
    async authinfoRetrieve(wh) {
      // Получение информации о текущей аутентификации
      // без параметров
      console.log('authinfoRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.account.authinfo.retrieve()})
      return ctx.$services.storage.put_response(response, wh)
    }
    async changeCreate(wh, data) {
      // data: IdentityChange
      // Смена роли
      console.log('changeCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.change.create(), body: data})
      return ctx.$services.storage.put_response(response, wh)
    }
    async companyRolesList(wh, id) {
      // Список ролей в компании
      console.log('companyRolesList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.roles.list(id)})
      return ctx.$services.storage.put_response(response, wh)
    }
    async logoutDelete(wh, data) {
      // data: Logout
      // Выход из системы для временных токенов
      // Данный метод действует только для временных токенов. При вызове без параметров прекращается только текущая сессия. Для постоянного токена - ошибка 400. 
      console.log('logoutDelete')
      await ctx.$services.fetch.request({config: endpoints.account.logout.delete(), body: data})
      ctx.$services.storage.del('{dt}', id, wh)
      return null
    }
    async myrolesList(wh, {limit, offset, page} = {}) {
      // Получение списка доступных мне ролей, за исключением CUSTOMER
      // без параметров
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('myrolesList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.myroles.list(params)})
      if (limit) {
        return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
      } else {
        return ctx.$services.storage.put_response(response, wh)
      }
    }
  }

  ctx.$services.accountApi = new AccountApiMethodsCls()
}

