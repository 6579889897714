import { cloneDeep } from 'lodash'
import moment from 'moment'
import constants from '@/config/constants'

const maxNumberOfDays = constants.chessSettings.maxNumberOfDays

const getStatusesInGivenRangeWithSort = (statuses = [], startDate, endDate) => {
  return statuses.filter(status => {
    const isSameOrAfterDate = moment(status.date).isSameOrAfter(startDate)
    const isSameOrBeforeDate = moment(status.date).isSameOrBefore(endDate)
    return isSameOrAfterDate && isSameOrBeforeDate
  })?.sort((a,b) => {
    return new Date(a.date) - new Date(b.date);
  })
}

const getMappedStatuses = (roomId, dates, limit, statuses = []) => {
  const result = []
  let limitIndex = 0
  for (let index = 0; index < limit; index++) {
    const dateFromDates = dates?.[index]?.value
    if (dateFromDates === statuses[limitIndex]?.date) {
      result.push(statuses[limitIndex])
      limitIndex += 1
    } else {
      result.push({
        date: dateFromDates,
        id: dateFromDates,
        locked: false,
        locked_for_check_in: false,
        room: roomId
      })
    }
  }
  return result
}

const getDatesByStartAndLimit = (startDate, limit) => {
  const result = []
  for (let index = 0; index < limit; index++) {
    const date = moment(startDate).add(index, 'd').format('YYYY-MM-DD')
    result.push({
      id: date,
      value: date
    })
  }

  const firstDateIndex = result.findIndex(item => item.value === startDate)
  if (firstDateIndex !== -1) {
    return result.slice(firstDateIndex, firstDateIndex + limit)
  }
  return []
}

const setReservations = (rooms, reservations) => {
  rooms.forEach((item) => {
    reservations.forEach((reservation) => {
      let total = 0
      if (item.id === reservation.category) {
        item.data[0].statuses.forEach((status, index) => {
          const dateIn = moment(reservation.date_in)
          const dateOut = moment(reservation.date_out)
          const statusDate = moment(status.date)
          const isAfter = statusDate.isSameOrAfter(dateIn)
          const isBefore = statusDate.isSameOrBefore(dateOut)
          if (isAfter && isBefore) {
            total = total + 1
            status.reservation = cloneDeep(reservation)
            status.reservation.isFirstDay =
              statusDate.format('YYYY-MM-DD') ===
              dateIn.format('YYYY-MM-DD')
            status.reservation.isLastDay =
              statusDate.format('YYYY-MM-DD') ===
              dateOut.format('YYYY-MM-DD')

            if (status.reservation.isLastDay) {
              item.data[0].statuses[index - total + 1].reservation.total =
                total
            }
          }
        })
      }
    })
  })
}


export const sectionBookingAdapter = ({rooms = [], categories = {}, reservations = [], limit = maxNumberOfDays, date}) => {
  const startDate = date || moment().format('YYYY-MM-DD')
  const endDate = moment(startDate).add(limit - 1, 'd').format('YYYY-MM-DD')
  const resultDates =  getDatesByStartAndLimit(startDate, limit)
  const resultData = []

  Object.values(categories).forEach(category => {
    const categoryData = {...category, value: category.name, data: []}
    rooms.forEach(room => {
      if (room.category === category.id) {
        const filtredStatuses = getStatusesInGivenRangeWithSort(room.statuses, startDate, endDate)
        const resultStatus = getMappedStatuses(room.id, resultDates, limit, filtredStatuses)
        room.statuses = resultStatus
        categoryData.data.push({
          ...room,
          value: room.number,
        })
      }
    })
    resultData.push(categoryData)
  })

  setReservations(resultData, reservations)

  return {
    data: resultData || [],
    dates: resultDates || []
  }
}