<template>
  <div v-if="ready" class="">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="body flex-grow-1 bg-body">
        <router-view class="mx-3 mt-3" />
      </div>
      <AppFooter />
    </div>
    <AppAside />
  </div>
</template>,
<script>

import AppAside from '@/components/AppAside.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import {inject, ref, onMounted, computed} from "vue";
import {useRouter} from "vue-router";

const WH = 'TOP'

export default {
  name: 'DefaultLayout',
  components: {
    AppAside,
    AppFooter,
    AppHeader,
    AppSidebar,
  },
  setup() {
    const router = useRouter()
    const {auth, userApi } = inject('services')
    const bus = inject('bus')

    const isAuthenticated = ref(false)
    const isLoaded = ref(false)
    const myIdentity = ref({})
    const myUser = ref({})

    const ready = computed(()=>{
      return isAuthenticated.value && isLoaded.value
    })

    const refreshAuthentication = () => {
      isAuthenticated.value = auth.isAuthenticated()
      if (!isAuthenticated.value) {
        console.warn('AuthFailed ==> Login')
        router.push({ name: 'Login' })
      }
    }

    onMounted(async () => {
      console.log('DefaultLayout.onMounted')

      isAuthenticated.value = await auth.goAuthenticated(false, true, 'DefaultLayout.onMounted')

      console.log(`DefaultLayout onMounted isAuthenticated.value ${isAuthenticated.value}`)

      if (!isAuthenticated.value) {
        console.log('==>Login')
        router.push({ name: 'Login' })
      }
      else {
        myIdentity.value = await auth.getIdentity()
        console.log(`DefaultLayout onMounted myIdentity.value`, myIdentity.value)
        console.log(`DefaultLayout onMounted myIdentity.value.user`, myIdentity.value.user)
        myUser.value = await userApi.userRetrieve(WH, myIdentity.value.user)
        console.log(`DefaultLayout onMounted myUser.value ${ myUser.value}`,)
        isLoaded.value = true
      }

      setInterval(refreshAuthentication, 1000)
    })

    bus.on('authentication-changed', async () => {
      myIdentity.value = await auth.getIdentity()
      console.log('DefaultLayout authentication-changed myIdentity', myIdentity.value.__storage_counter)
      myUser.value = await userApi.userRetrieve(WH, myIdentity.value.user)
      console.log('DefaultLayout authentication-changed myUser', myUser.value.__storage_counter)
    })

    return {
      ready,
      myUser,
      myIdentity,
      isAuthenticated,
      isLoaded
    }
  },
}
</script>

<style lang="scss" scoped>
.body {
  background: var(--cui-gray-100);
  position: relative;
  max-height: calc(100vh - 164px);
  overflow: auto;
}
</style>
