import {
  getObjections,
  getQuestions,
  startScript,
  getAplications,
} from '@/api/aplicationsMethods'
const state = () => ({
  aplications: [], // Все заявки

  questions: [],
  objections: [],
  script: [],
})

const getters = {
  aplications: (state) => {
    return state.aplications
  },

  questions: (state) => {
    return state.questions
  },
  objections: (state) => {
    return state.objections
  },
  script: (state) => {
    return state.script
  },
}

const mutations = {
  SET_SCRIPT(state, payload) {
    state.script = payload
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload
  },
  SET_OBJECTIONS(state, payload) {
    state.objections = payload
  },
  SET_APLICATIONS(state, payload) {
    state.aplications = payload
  },
}

const actions = {
  async SET_QUESTIONS({ commit }, payload) {
    const response = payload ? payload : await getQuestions()
    commit('SET_QUESTIONS', response)
  },
  async SET_OBJECTIONS({ commit }, payload) {
    const response = payload ? payload : await getObjections()
    commit('SET_OBJECTIONS', response)
  },
  async SET_SCRIPT({ commit }, payload) {
    const response = payload ? payload : await startScript()
    commit('SET_SCRIPT', response)
  },

  async SET_APLICATIONS({ commit }, payload) {
    const response = payload ? payload : await getAplications()
    commit('SET_APLICATIONS', response)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
